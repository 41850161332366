import React, { useState } from 'react';
import {
  FaBars,
  FaTimes,
  FaGithub,
  FaLinkedin,
} from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import Logo from '../../assets/Portfolio/logo1.png';
import { Link } from 'react-scroll';


const Navbar = ({ activeSection }) => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  const sections = ["home", "about", "skills", "work", "contact"];
  const getClassName = (section) => (activeSection === `${section}-nav` ? "bg-[#ff8432]" : "link link-nav-main");


  return (
    <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300 z-[2000]'>
      <div>
        <Link to='home' smooth={true} duration={500}>
          <img src={Logo} alt='Logo Image' style={{ width: '200px', cursor: 'pointer' }} />
        </Link>
      </div>

      {/* menu */}
        
      <ul className='hidden md:flex font-semibold gap-1'>
        {sections.map((section) => (
          <li key={section} className={getClassName(section)} style={{paddingTop: '5px'}} >
            <Link to={section} smooth={true} duration={500}>
              {section.charAt(0).toUpperCase() + section.slice(1)}
            </Link>
          </li>
        ))}
      </ul>

      {/* Hamburger */}
      <div onClick={handleClick} className='md:hidden z-10'>
        {!nav ? <FaBars size={30}/> : <FaTimes size={30}/>}
      </div>

      {/* Mobile menu */}
      <div className={!nav ? 'fixed left-[-100%] z-50' : 'md:hidden fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-300 bg-[#0a192f] ease-in-out duration-500 z-50'}>
          <div className='px-4 pt-[22px]'>
            <Link to='home' smooth={true} duration={500}>
              <img src={Logo} alt='Logo Image' style={{ width: '200px', cursor: 'pointer' }} />
            </Link>
          </div>

          <ul className='uppercase p-4 font-semibold'>
            <li className='p-4 border-b border-gray-300 hover:cursor-pointer hover:bg-[#ff8432] hover:text-white'>
              {' '}
              <Link onClick={handleClick} to='home' smooth={true} duration={500}>
                Home
              </Link>
            </li>
            <li className='p-4 border-b border-gray-300 hover:cursor-pointer hover:bg-[#ff8432] hover:text-white'>
              {' '}
              <Link onClick={handleClick} to='about' smooth={true} duration={500}>
                About
              </Link>
            </li>
            <li className='p-4 border-b border-gray-300 hover:cursor-pointer hover:bg-[#ff8432] hover:text-white'>
              {' '}
              <Link onClick={handleClick} to='skills' smooth={true} duration={500}>
                Skills
              </Link>
            </li>
            <li className='p-4 border-b border-gray-300 hover:cursor-pointer hover:bg-[#ff8432] hover:text-white'>
              {' '}
              <Link onClick={handleClick} to='work' smooth={true} duration={500}>
                Work
              </Link>
            </li>
            <li className='p-4 hover:cursor-pointer hover:bg-[#ff8432] hover:text-white'>
              {' '}
              <Link onClick={handleClick} to='contact' smooth={true} duration={500}>
                Contact
              </Link>
            </li>
          </ul>
        </div>

      {/* Social icons */}
      <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
        <ul>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='https://www.linkedin.com/in/zachary-wood-030617231/'
            >
              Linkedin <FaLinkedin size={30} />
            </a>
          </li>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333]'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='https://github.com/Zachary-Woo'
            >
              Github <FaGithub size={30} />
            </a>
          </li>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='mailto:zackwood555@gmail.com'
            >
              Email <HiOutlineMail size={30} />
            </a>
          </li>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]'>
            <a
              className='flex justify-between items-center w-full text-gray-300'
              href='/resume'
            >
              Resume <BsFillPersonLinesFill size={30} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;