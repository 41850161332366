import { faComment } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DRHeader = () => {
  return (
    <div className="bg-gradient-to-r from-orange-700 to-orange-400 flex items-center justify-center p-4 shadow">
      <div>
        <h4 className="text-lg text-white flex items-center justify-center">
          <FontAwesomeIcon icon={faComment} className="mr-2" />
          AI Chatbot - DocR
        </h4>
        <p className="text-sm text-white">
          Ask a question about UNCW's Security Policies!
        </p>
      </div>
    </div>
  );
};

export default DRHeader;