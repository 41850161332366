import { Link } from 'react-router-dom';

import Chatbot from '../../../assets/Portfolio/projects-images/ChatbotStandard.jpg';
import DocR from '../../../assets/Portfolio/projects-images/ChatbotDocR.jpg';
import WorkoutApp from '../../../assets/Portfolio/projects-images/workoutMidFidelity.jpg';

export const data=[
    {
        id:1,
        name:"Chatbot UI",
        image:Chatbot,
        github:"https://github.com/Zachary-Woo/ChatbotUI/tree/main",
        live: <Link to="/chatbot">/chatbot</Link>,
    },
    {
        id:2,
        name:"Local Data Querying with GPT",
        image:DocR,
        github:"https://github.com/Zachary-Woo/WITX_webapp_demo",
        live:<Link to="/doc-r">/chatbot</Link>,
    },
    {
        id:3,
        name:"Mid-Fidelity Workout Screens (Scale Browser Down for Full Effect)",
        image:WorkoutApp,
        github:"https://github.com/Zachary-Woo/WorkoutAppMid-Fidelity",
        live:"https://dinobytez.com/",
    }
]