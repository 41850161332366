import React, { useState } from 'react';

export default function AboutToggle() {
  const [selectedNav, setSelectedNav] = useState(0);

  function toggle(e) {
    setSelectedNav(e);
  }

  return (
    <div id='about' name='about'>
      <div className="bg-[#0a192f] text-gray-300 py-[20vh]">

          {/* Navigation Menu To Toggle Content */}
          <nav className="pb-2">
            <ul className="flex justify-center max-w-screen overflow-hidden space-x-2 sm:space-x-4 md:space-x-6">
              <li
                onClick={(e) => toggle(0)}
                className={selectedNav === 0 ? 'active link px-2 py-1' : 'link px-2 py-1'}
              >
                <span className="text-sm font-semibold sm:text-lg md:text-2xl md:font-bold">Greeting</span>
              </li>
              <li
                onClick={(e) => toggle(1)}
                className={selectedNav === 1 ? 'active link px-2 py-1' : 'link px-2 py-1'}
              >
                <span className="text-sm font-semibold sm:text-lg md:text-2xl md:font-bold">Cybersecurity</span>
              </li>
              <li
                onClick={(e) => toggle(2)}
                className={selectedNav === 2 ? 'active link px-2 py-1' : 'link px-2 py-1'}
              >
                <span className="text-sm font-semibold sm:text-lg md:text-2xl md:font-bold">Cloud Computing</span>
              </li>
              <li
                onClick={(e) => toggle(3)}
                className={selectedNav === 3 ? 'active link px-2 py-1' : 'link px-2 py-1'}
              >
                <span className="text-sm font-semibold sm:text-lg md:text-2xl md:font-bold">Artificial Intelligence</span>
              </li>
            </ul>
          </nav>
        {selectedNav === 0 && <Main />}
        {selectedNav === 1 && <Second />}
        {selectedNav === 2 && <Third />}
        {selectedNav === 3 && <Fourth />}
      </div>
    </div>
  );
}

const Main = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full pt-8">
      <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
        <div className='sm:text-right text-4xl font-bold'>
          <p>Hi, I'm Zachary Wood. </p>
          <p>It's nice to meet you.</p>
        </div>
        <div>
          <p>I am dedicated to leveraging cutting-edge technologies to build innovative and 
            impactful software solutions. Through my experience in full-stack development, 
            cloud solutions, and cybersecurity, I seek to create user-friendly and secure 
            solutions that push the boundaries of what's possible, while empowering 
            individuals and businesses to make a difference.</p>  
        </div>
      </div>
    </div>
  );
};

const Second = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full pt-8">
      <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
        <div className='sm:text-right text-4xl font-bold'>
          <p>Recently I earned my CompTIA Security+.</p>
        </div>
        <div>
        <p>At HackWarz, I honed my red-team expertise by simulating advanced cyber attacks and 
          identifying vulnerabilities. During the Palmetto Cyber Defense Competition (PCDC), I 
          demonstrated my blue-team capabilities by successfully defending against persistent 
          red-team assaults and mitigating social engineering tactics. Additionally, I gained 
          valuable hands-on experience with Security Onion at TracerFIRE, a forensics-focused, 
          Jeopardy-style Capture the Flag event that challenged my analytical and problem-solving 
          skills.</p> 
        </div>
      </div>
    </div>
  );
};

const Third = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full pt-8">
      <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
        <div className='sm:text-right text-4xl font-bold'>
          <p>I earned my AWS Certified Cloud Practitioner for fun!</p>
        </div>
        <div>
        <p>As part of my commitment to expanding my expertise and embracing cloud computing, I 
          achieved the AWS Certified Cloud Practitioner certification. To gain practical experience, 
          I designed and built a serverless web application, implemented static web hosting using S3 
          and CloudFront caching, and configured a Virtual Private Cloud (VPC) across three 
          Availability Zones with dedicated public and private subnets. I am currently furthering my 
          knowledge by pursuing the AWS Solutions Architect: Associate certification.</p>
        </div>
      </div>
    </div>
  );
};

const Fourth = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full pt-8">
      <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
        <div className='sm:text-right text-3xl font-bold'>
          <p>I'm studying AI Adaptive Learning Systems for Cybersecurity Awareness Training as a summer (DIS)!</p>
        </div>
        <div>
        <p>I developed a chatbot that leverages word embeddings and cosine similarity to query local data 
          and interact with the GPT-based Davinci-003 model for answering data-related questions. This project 
          ignited my passion for AI research, leading to a unique opportunity to conduct a directed individual 
          study (DIS) this summer. My research will focus on AI Adaptive Learning Systems for Cybersecurity 
          Awareness Training, and I am thrilled to dedicate my efforts towards exploring this fascinating 
          intersection of work and research!</p>
        </div>
      </div>
    </div>
  );
};