import React from 'react'

const Contact = () => {
  return (
    <div id='contact' name='contact' className='w-full h-screen bg-[#0a192f] flex justify-center items-center p-4'>
        <form method='POST' action="https://getform.io/f/293a7a91-8e6b-405c-b2aa-4760294e2764" className='flex flex-col max-w-[600px] w-full opacity-[0.85]'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-[#ff8432] text-gray-300'>Contact</p>
                <p className='text-gray-300 py-4'>// Submit the form below or shoot me an email - zackwood555@gmail.com</p>
            </div>
            <input className='bg-gray-100 p-2' type="text" placeholder='Name' name='name' />
            <input className='my-4 p-2 bg-gray-100' type="email" placeholder='Email' name='email' />
            <textarea className='bg-gray-100 p-2' name="message" rows="10" placeholder='Message'></textarea>
            <button className='text-white border-2 hover:bg-[#ff8432] hover:border-[#ff8432] px-4 py-3 my-8 mx-auto flex items-center'>Let's Collaborate</button>
        </form>
    </div>
  )
}

export default Contact