import React from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import HeroImage from '../../assets/Portfolio/hero-image.png';
import { Link } from 'react-scroll';

const Home = () => {
  return (
    <div id='home' name='home' className='w-full h-screen bg-[#0a192f] md:flex'>
      {/* Container */}
      <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
        <p className='text-[#ff8432]'>Hi, my name is</p>
        <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]'>
          Zachary Wood
        </h1>
        <h2 className='text-4xl sm:text-7xl font-bold text-[#8892b0]'>
          I'm a Software<br></br>
          Engineer.
        </h2>
        <p className='text-[#8892b0] py-4 max-w-[700px]'>
          I’m a software engineer with a passion for Cloud, AI, and Cybersecurity solutions. 
          Currently, I’m investigating the development and application of AI-driven 
          adaptive learning systems for enhancing cybersecurity awareness training.
        </p>
        <div>
          <Link to='work' smooth={true} duration={500}>
            <button className='text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-[#ff8432] hover:border-[#ff8432]'>
              View Work
              <span className='group-hover:rotate-90 duration-300'>
                <HiArrowNarrowRight className='ml-3 ' />
              </span>
            </button>
          </Link>
        </div>
      </div>
      {/* Hero-Image */}
      <div className='hidden md:flex md:w-1/2 items-center p-5 max-h-full'>
        <img src={HeroImage} alt='Portfolio photo of a very handsome man' className='rounded-2xl mx-auto w-2/3 md:w-full max-w-[500px] max-h-[500px] object-cover'/>
      </div>
    </div>
  );
};

export default Home;