import React, { useState, useRef, useEffect } from 'react';
import DRHeader from './DRHeader';
import DRTextChat from './DRTextChat';
import DRFooter from './DRFooter';

const DocRWidget = () => {
  const [messages, setMessages] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [input, setInput] = useState('');
  const messageListRef = useRef(null);

  // Add this useEffect hook to scroll down to the newest message
  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const handleClick = () => {
    sendMessage();
  };

  const sendMessage = () => {
    if (input.trim()) {
      setMessages([...messages, { sender: 'me', text: input }]);
      setInput('');
      handleResponse(input);
    }
  };

  //I just removed all extra code this function saves
  //Currently it just sends a request to the server it nolonger saves a file to the tmp folder
  const handleAudioRecording = async () => { 
    const formData = new FormData();
    formData.append('audio', 'audio.wav');
    try {
      const response = await fetch('http://localhost:5000/api/audio', {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      console.log('Transcribed text:', data.transcribed_text);
      console.log('Bot response:', data.response);
    } catch (error) {
      console.error('Error sending audio to server:', error);
    }
  };

  const handleResponse = async (question) => {
    // Extract the history from the messages array
    const history = messages.filter((message) => message.sender === 'me').map((message) => ({
      question: message.text,
      answer: '',
    }));
  
    console.log('Sending request:', { question, history });
  
    const response = await fetch('http://localhost:5000/api/response', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ input: question, history: history }),
    });
  
    const data = await response.json();
    console.log('Received response:', data);
    // Include both the user's message and the bot's response
    setMessages((prevMessages) => [...prevMessages, { sender: 'bot', text: data.response }]);
  };
  

  return (
    <div className="flex flex-col h-screen">
      <DRHeader />
      <DRTextChat messages={messages} messageListRef={messageListRef} handleResponse={handleResponse} />
      <DRFooter
        isRecording={isRecording}
        setIsRecording={setIsRecording}
        input={input}
        handleChange={handleChange}
        handleKeyDown={handleKeyDown}
        handleClick={handleClick}
        handleAudioRecording={handleAudioRecording}
      />
    </div>
  );
};

export default DocRWidget;