import { useState } from "react";

import SkillScroller from "./SkillsCarousel";
import Technologies from "./Technologies";
import Certifications from "./Certifications";
import AllSkills from "./AllSkills";

export default function SkillToggle() {
  const [selectedNav, setSelectedNav] = useState(0);

  function toggle(e) {
    setSelectedNav(e);
  }

  return (
    <div className="">
      <div className="bg-[#0a192f] text-gray-300 py-[20vh]">
          <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <div className='px-4'>
              <p className='text-4xl font-bold inline border-b-4 border-[#ff8432]'>Skills</p>
              <p className='py-4'>// Here are a few certifications I have some technologies I've worked with</p>
            </div>
          </div>

          {/* Navigation Menu To Toggle Content */}
          <nav className="pb-2">
            <ul className="flex justify-center max-w-screen overflow-hidden space-x-2 sm:space-x-4 md:space-x-6">
              <li
                onClick={(e) => toggle(0)}
                className={selectedNav === 0 ? 'active link px-2 py-1' : 'link px-2 py-1'}
              >
                <span className="text-sm font-semibold sm:text-lg md:text-2xl md:font-bold">Skills</span>
              </li>
              <li
                onClick={(e) => toggle(1)}
                className={selectedNav === 1 ? 'active link px-2 py-1' : 'link px-2 py-1'}
              >
                <span className="text-sm font-semibold sm:text-lg md:text-2xl md:font-bold">Certifications</span>
              </li>
              <li
                onClick={(e) => toggle(2)}
                className={selectedNav === 2 ? 'active link px-2 py-1' : 'link px-2 py-1'}
              >
                <span className="text-sm font-semibold sm:text-lg md:text-2xl md:font-bold">Technologies</span>
              </li>
              <li
                onClick={(e) => toggle(3)}
                className={selectedNav === 3 ? 'active link px-2 py-1' : 'link px-2 py-1'}
              >
                <span className="text-sm font-semibold sm:text-lg md:text-2xl md:font-bold">All</span>
              </li>
            </ul>
          </nav>
        {selectedNav === 0 && <Main />}
        {selectedNav === 1 && <Second />}
        {selectedNav === 2 && <Third />}
        {selectedNav === 3 && <Fourth />}
      </div>
    </div>
  );
}

const Main = () => {
  return (
    <div className="">
      <SkillScroller />
    </div>
  );
};

const Second = () => {
  return (
    <div className="">
      <Certifications />
    </div>
  );
};

const Third = () => {
  return (
    <div className="">
      <Technologies />
    </div>
  );
};

const Fourth = () => {
  return (
    <div className="max-w-screen">
      <AllSkills />
    </div>
  );
};