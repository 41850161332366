import React from 'react';
import { cards } from './data/skillCards.js';

const AllCerts = () => {
  const itemWidth = '225px'; // Set a fixed width for each item

  // Filter set to allow all cards
  const techCards = cards.filter((card) => card.isCert || !card.isCert);

  return (
    <div id='skills' name='skills' className='w-full bg-[#0a192f]'>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full text-gray-300'>
        <div className='w-full grid grid-cols-1 md:grid-cols-4 gap-4 text-center py-8'>
        {techCards.map((card) => (
          <div key={card.id} className='mx-[3.5rem] my-4' style={{ width: itemWidth }}>
            <div className={`shadow-md ${card.style} sm:hover:scale-110 duration-500 flex flex-col justify-center items-center`}>
                <img className="w-20 mx-auto" src={card.src} alt={card.text} />
                <p className="my-4">{card.text}</p>
            </div>
          </div>
        ))}
        </div>
      </div>
    </div>
  );
};

export default AllCerts;