import React from 'react';

const CbInfo = () => {
  return (
    <div className="bg-white p-2 rounded-md text-black shadow-md">
      <p>Click the expand button below to query personal data.</p>
    </div>
  );
};

export default CbInfo;
