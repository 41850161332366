export const Rev = [
    {
        id: 1,
        name: "Oliver Smith",
        title: "Astounded Citizen",
        description: "I was at a loss when my cat got stuck on the roof of a five-story building. Zachary appeared out of nowhere, scaled the building, and rescued my beloved pet! Truly a modern-day superhero!",
        image: "https://pbs.twimg.com/profile_images/1491500469323186177/ZLXKjvML_400x400.jpg",
        stars: 5
        },
        
        {
        id: 2,
        name: "Sophia Johnson",
        title: "Grateful Aunt",
        description: "When my nephew was trapped in a burning building, it was Zachary who bravely charged into the flames and emerged with him unscathed. He's our family's hero and guardian angel!",
        image: "https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        stars: 5
        },
        
        {
        id: 3,
        name: "Liam Williams",
        title: "Studious Fish Owner",
        description: "Zachary went above and beyond when my fish needed help studying for a math test. He dedicated countless hours to ensure my fish aced the exam. We couldn't have done it without him!",
        image: "https://pbs.twimg.com/profile_images/715279274698285056/18YPr0e7_400x400.jpg",
        stars: 5
        },
        
        {
        id: 4,
        name: "Ava Brown",
        title: "Impressed Neighbor",
        description: "Zachary single-handedly organized our neighborhood's annual block party, and it was the best one yet! The food, games, and entertainment were all top-notch, and he even coordinated a surprise fireworks display!",
        image: "https://media.istockphoto.com/id/1068259448/photo/an-elderly-woman-standing-by-the-window-looking-out-shot-through-glass.jpg?s=612x612&w=0&k=20&c=aOssnOf4X8GDWewH9751Sn9gum5xI21OAgx2ceptQLU=",
        stars: 5
        },
        
        {
        id: 5,
        name: "Hannah Davis",
        title: "Awestruck Scientist",
        description: "When our research facility was on the brink of a catastrophic meltdown, Zachary came to the rescue. With nerves of steel, he expertly averted disaster, saving countless lives and invaluable research data!",
        image: "https://i.gifer.com/g0pO.gif",
        stars: 5
        },
        
        {
        id: 6,
        name: "Isabella Miller",
        title: "Relieved Parent",
        description: "My son's school play was about to be canceled due to a lack of funding. Zachary swooped in and organized a phenomenal fundraising event that not only saved the play but also provided new costumes and props!",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT1pH4vlG3gK37jLZO6yVknNTRMItRsmhFShyEjI27cg&usqp=CAU&ec=48665699",
        stars: 5
        },
        
        {
        id: 22,
        name: "Finny the Fish",
        title: "Academically Accomplished Fish",
        description: "I, Finny the Fish, was swimming in circles trying to pass my math test when Zachary stepped in. With his patient guidance and unique underwater teaching methods, I aced the exam and swam into a future of scholastic success. Forever grateful!",
        image: "https://media.tenor.com/c5q6WLf6h8MAAAAM/fish.gif",
        stars: 5
        },
        
        {
        id: 8,
        name: "Lucas Moore",
        title: "Enthusiastic Concertgoer",
        description: "When the headliner of our favorite music festival canceled last minute, Zachary stepped up and delivered a mind-blowing performance that had the entire crowd on their feet. He's a true rock star!",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXzD5btKdlgIiEi-8Ip8VxwOCSnsdXiiutu8rMoo55GZuZy2Gf5soskjZxL8wHRgC0Okr5Q6DJdGA&usqp=CAU&ec=48665699",
        stars: 5
        },
        
        {
        id: 9,
        name: "Harper Taylor",
        title: "Inspired Writer",
        description: "I had the worst case of writer's block, but after a chance encounter with Zachary, my creativity flowed like never before! He's a muse, a mentor, and a guiding light for all aspiring writers.",
        image: "https://i.guim.co.uk/img/media/ad086eea10f0408c6bc730eefe6e81de53a9d313/99_289_4353_2611/master/4353.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=fd3f20eaaa3efc1f5bd07d9720730150",
        stars: 5
        },
        
        {
        id: 10,
        name: "Ella Taylor",
        title: "Rescued Adventurer",
        description: "I was stranded in the desert, delirious and dehydrated, when I saw a figure approaching on a camel. To my amazement, it was Zachary! He guided me back to civilization and nursed me back to health. I owe him my life!",
        image: "https://img.freepik.com/free-photo/stylish-young-woman-khaki-dress-walking-desert-traveling-africa-safari-wearing-hat-backpack-taking-photo-vintage-camera_285396-9196.jpg?w=360",
        stars: 5
        },
        
        {
        id: 11,
        name: "Mason Clark",
        title: "Marooned Sailor",
        description: "When my boat capsized in the middle of the ocean, I thought I was done for. Miraculously, Zachary appeared on a jet ski, pulled me from the water, and got me safely back to shore. He's a true lifesaver!",
        image: "https://media.istockphoto.com/id/474147974/photo/old-man-and-the-sea-looking-out.jpg?s=612x612&w=0&k=20&c=Idt2vgAFNnSbF5RD4qzFexIo1j1NFAr4qrDqfsiB1z0=",
        stars: 5
        },
        
        {
        id: 12,
        name: "Camillo the Camel",
        title: "Grateful Desert Companion",
        description: "As a humble desert camel, I've seen many things, but nothing compares to Zachary's heroics. When he took me to rescue a stranded traveler, I knew I was in the presence of greatness. He's a true champion of the animal kingdom!",
        image: "https://media0.giphy.com/media/qYr8p3Dzbet5S/giphy.gif",
        stars: 5
        },
        
        {
        id: 13,
        name: "Ethan Hall",
        title: "Grateful Hiker",
        description: "I was lost in the wilderness, facing a treacherous mountain pass when Zachary descended from the sky in a helicopter, winched me to safety, and returned me to my friends and family. He's a true guardian angel!",
        image: "https://media.istockphoto.com/id/1174712683/photo/smiling-african-american-hiker-walking-with-backpack-in-nature.jpg?b=1&s=170667a&w=0&k=20&c=oaURaz5_tqnyuzWX_RZ-4dt33yxSgHMnyyXrgQst8oA=",
        stars: 5
        },
        
        {
        id: 14,
        name: "Sophie Patel",
        title: "Amazed Zookeeper",
        description: "Our zoo faced closure due to a sudden outbreak of a rare animal disease. Zachary showed up with a team of specialists, found the cure, and saved every single animal. Our zoo is thriving, thanks to his incredible efforts!",
        image: "https://i.guim.co.uk/img/media/b8788341bbcf9dacd643c6b75e2d798aea765569/0_0_1095_657/master/1095.jpg?width=1200&quality=85&auto=format&fit=max&s=08009c089dc5bfb665c5118315864712",
        stars: 5
        },
        
        {
        id: 25,
        name: "Oscar Turner",
        title: "Astonished Astronaut",
        description: "During a critical spacewalk, my tether detached, and I was drifting away from the spacecraft. In an astounding feat, Zachary launched a rescue mission, retrieved me from the void, and saved my life. He's truly out of this world!",
        image: "https://www.nasa.gov/sites/default/files/thumbnails/image/s84-27232.jpg",
        stars: 5
        }
    ];