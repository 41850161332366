import React from 'react'
import StandardChatbot from '../components/ChatbotApp/ChatbotWidget'

const Chatbot = () => {
  return (
    <div>
      <StandardChatbot />
    </div>
  )
}

export default Chatbot