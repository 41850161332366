import React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Resume from '../assets/Portfolio/Zachary_Wood_Resume.pdf';

const ResumeDoc = () => {
  return (
    <div>
        <p className='flex justify-center px-2 py-10 bg-red-500 opacity-80 text-white font-semibold text-xl'>Please Note: <br></br>I've yet to make a personal pdf viewer in React JS <br></br>so this is just a temporary placeholder until I build one.</p>
        <div className='flex justify-center'>
        <Document file={Resume}>
            <Page pageNumber={1} />
        </Document>
        </div>
    </div>
  );
};

export default ResumeDoc;