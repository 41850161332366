import React, { Component } from "react";
import Slider from "react-slick";
import { ReviewCards } from './Card';
import { Rev } from '../data/reviewCards';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute 
                top-[50%]
                w-[80px]
                h-[50px]
                mt-[-25px]
                
                cursor-pointer
                bg-[#ff8432]
                shadow-[0_9px_26px_rgba(58,87,135,0.45)]
                transition-all 
                duration-200 
                ease-linear
                outline-none
                
                right-[-30px]
                pr-[20px]
                rounded-l-[60px]
                hover:right-[-15px]
                hover:bg-[rgba(6,27,65,0.4)]"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faCaretRight} className='relative left-[15px] top-[10px] h-[30px] text-white'/>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute 
                top-[50%]
                w-[80px]
                h-[50px]
                mt-[-25px]

                cursor-pointer
                bg-[#ff8432]
                shadow-[0_9px_26px_rgba(58,87,135,0.45)]
                transition-all 
                duration-200 
                ease-linear
                outline-none
                
                left-[-30px]
                pl-[20px]
                rounded-r-[60px]
                hover:left-[-15px]
                hover:bg-[rgba(6,27,65,0.4)]"
      onClick={onClick}
      style={{ zIndex: "1000" }}
    >
      <FontAwesomeIcon icon={faCaretLeft} className='relative left-[30px] top-[10px] h-[30px] text-white'/>
    </div>
  );
}

export default class CustomArrows extends Component {
  render() {
    const settings = {
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      centerMode: true,
      centerPadding: "2rem",
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      speed: 500,
      adaptiveHeight: true,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            centerPadding: "20px",
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "20px",
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "10px",
          }
        }
      ]
    };

      return (
          <div className="w-full overflow-hidden mb-[5vh]">
            <Slider {...settings} className="py-[30px]">
              {Rev.map((item) => (
                <ReviewCards data={item} />
              ))}
            </Slider>
          </div>
      );
    }
  }