import React, { useState, useEffect } from 'react';
import About from "../components/Portfolio/About";
import Contact from "../components/Portfolio/Contact";
import Home from "../components/Portfolio/Home";
import Navbar from "../components/Portfolio/Navbar";
import Work from "../components/Portfolio/Work";
import SkillToggle from '../components/Portfolio/SkillToggle';
import Reviews from '../components/Portfolio/Reviews';


function Portfolio() {

  const [activeSection, setActiveSection] = useState("home");

  useEffect(() => {
    const handleScroll = () => {
      const sectionIds = ["home", "about", "skills", "work", "contact"];
      const navbarIds = sectionIds.map((id) => `${id}-nav`);

      const scrollTop = window.pageYOffset;
      const sectionsTop = sectionIds.map((id) => document.getElementById(id).offsetTop);

      const offset = 300; //This value is the scroll sensitivity to change the active section

      const activeIndex = sectionsTop.findIndex((top, index, array) => {
        if (index === array.length - 1) return scrollTop >= top - offset;
        return scrollTop >= top - offset && scrollTop < array[index + 1] - offset;
      });

      setActiveSection(navbarIds[activeIndex]);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div>
        <Navbar activeSection={activeSection} />
        <Home id="home" />
        <About id="about" />
        <SkillToggle id="skills"/>
        <Work id="work" />
        <Contact id="contact" />
        <Reviews />
    </div>
    </div>
  );
}

export default Portfolio;