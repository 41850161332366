import React from 'react'
import DocRUIDemo from '../components/ChatbotDocR/DocRWidget'

const DocRChatbot = () => {
  return (
    <div>
        <div className='flex flex-col items-center justify-center text-xl font-semibold'>
            <h1>Disclaimer: This is not connected to the Python Flask backend. This is just the UI I used for it.</h1>
            <h1>***** User message still posts to screen but page will crash due to no response. *****</h1>
        </div>
        <DocRUIDemo />
    </div>
  )
}

export default DocRChatbot