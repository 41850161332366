import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const DRFooter = ({
  isRecording,
  setIsRecording,
  input,
  handleChange,
  handleKeyDown,
  handleClick,
  handleAudioRecording,
}) => {

  const mediaRecorder = useRef(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.addEventListener('dataavailable', handleAudioRecording);
      mediaRecorder.current.start();
      console.log('Recording started');
    } catch (err) {
      console.error('Error while starting recording:', err);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.stop();
      console.log('Recording stopped');
    }
  };

  useEffect(() => {
    if (isRecording) {
      startRecording();
    } else {
      stopRecording();
    }

    return () => {
      stopRecording();
    };
  }, [isRecording]);
  

  return (
    <div className="bg-gradient-to-l from-orange-700 to-orange-400 flex items-center justify-center p-4 shadow-md">
      
      {/* This is commented out due to the function not working properly yet and not having enough time to debug it */}
      {/*
      <button
        onClick={() => setIsRecording(!isRecording)}
        className={`${
          isRecording
            ? 'animate-pulse bg-orange-200 text-red-600 hover:text-red-600'
            : 'text-white hover:bg-orange-200 hover:text-black'
        } p-2 rounded-full m-1`}
      >
        <FontAwesomeIcon icon={faMicrophone} className={isRecording ? 'text-red-600' : ''} />
      </button>
      */}

      <input
        type="text"
        value={input}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="Write a message..."
        className="border-none px-20 py-2 rounded-full text-center hover:bg-gray-200"
      />

      <button
        onClick={handleClick}
        className="px-2.5 py-1 m-1 text-white rounded-full hover:bg-orange-200 hover:text-black"
      >
        <FontAwesomeIcon icon={faPaperPlane} />
      </button>
    </div>
  );
};

export default DRFooter;